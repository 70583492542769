import Alpine from 'alpinejs';
import Flickity from 'flickity';
import Headroom from "headroom.js";
import PriorityNavScroller from 'priority-nav-scroller';
import { Fancybox } from '@fancyapps/ui';

function initializeScripts() {

    //HEADER SCROLL
    var headerElement = document.querySelector('.header-content');
    var headRoomOptions = {
        offset: 50,
        tolerance: {
            down: 5,
            up: 0
        },
        classes: {
            initial: 'headeroom',
            pinned: 'headroom--pinned',
            unpinned: 'headroom--unpinned',
            top: 'headroom--top',
            notTop: 'headroom--not-top',
            bottom: 'headroom--bottom',
            notBottom: 'headroom--not-bottom'
        }
    };
    var headroom = new Headroom(headerElement, headRoomOptions);
    headroom.init();
    //Make sure header video is visible on safari
    const video = document.querySelector('.header-video video');

    // if video exists
    if (video) {
        video.style.visibility = 'visible';
        video.style.opacity = '1';
    }

    //MODALS
    Fancybox.bind('[data-fancybox]', {
        // Animation options
        animated: true,
        showClass: 'fancybox-zoomIn',
        dragToClose: true,
        animationDuration: 500,
        zoomOpacity: 'auto',
        transition: 'slide',
        transitionDuration: 0,
        
        
        // Class names
        slideClass: '',
        baseClass: '',
        
        // Protection and controls
        protect: true,
        arrows: true,
        infobar: true,
        toolbar: true,
        keyboard: true,
        
        // Fullscreen options
        fullscreen: {
            autoStart: false
        },
        
        // Touch options
        touch: {
            vertical: true,
            momentum: true
        },
        
        // Slideshow options
        slideshow: {
            autoStart: false
        },
        
        // Thumbnail options
        thumbs: {
            autoStart: false,
            hideOnClose: true
        },
        
        // Hash navigation
        hash: true,
        
        // Toolbar configuration
        toolbar: {
            display: [
                'thumbs',
                'close'
            ]
        },
        
        // Iframe options
        iframe: {
            preload: false
        }
    });

    // move fancybox close button
    const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (mutation.addedNodes.length) {
            const closeBtn = document.querySelector('.f-button.is-close-btn');
            const fancyboxContainer = document.querySelector('.fancybox__container');
            
            if (closeBtn && fancyboxContainer) {
              fancyboxContainer.appendChild(closeBtn);
              observer.disconnect(); // Stop observing once moved
            }
          }
        });
      });
      
      observer.observe(document.body, {
        childList: true,
        subtree: true
      });

    //CAROUSEL
    // Select all carousel elements except the event carousel
    const carousels = document.querySelectorAll('.carousel:not(#event-carousel)');


    // Initialize Flickity on each carousel element
    if (carousels.length) {
        carousels.forEach(carousel => {
            new Flickity(carousel, {
                cellAlign: 'left',
                selectedAttraction: 0.01,
                friction: 0.15,
                freeScroll: false,
                prevNextButtons: true,
                pageDots: true,
                groupCells: 1,
                draggable: true,
                dragThreshold: 10
            });
        });
    }

    // Select the event carousel and initialize Flickity
    

    // CAROUSEL EQUAL HEIGHTS
    Flickity.prototype._createResizeClass = function() {
        this.element.classList.add('flickity-resize');
    };

    Flickity.createMethods.push('_createResizeClass');

    var resize = Flickity.prototype.resize;
    Flickity.prototype.resize = function() {
        this.element.classList.remove('flickity-resize');
        resize.call( this );
        this.element.classList.add('flickity-resize');
    };

    // smooth scroll
    // Select all links with hashes
    document.querySelectorAll('a[href*="#"]').forEach(link => {
        // Filter out links that don't actually link to anything
        if (
            link.getAttribute('href') !== '#' &&
            link.getAttribute('href') !== '#search' &&
            link.getAttribute('href') !== '#0'
        ) {
            link.addEventListener('click', function(event) {
                // On-page links
                if (
                    location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') &&
                    location.hostname === this.hostname
                ) {
                    // Figure out element to scroll to
                    const hash = this.hash;
                    const target = document.querySelector(hash) || document.querySelector(`[name='${hash.slice(1)}']`);

                    // Does a scroll target exist?
                    if (target) {
                        // Only prevent default if animation is actually gonna happen
                        event.preventDefault();

                        // Smooth scroll to target
                        target.scrollIntoView({
                            behavior: 'smooth'
                        });

                        // Calculate scroll position
                        const targetPosition = target.getBoundingClientRect().top + window.pageYOffset;

                        // Scroll with animation
                        window.scrollTo({
                            top: targetPosition,
                            behavior: 'smooth'
                        });

                        // Set focus after scrolling
                        setTimeout(() => {
                            target.focus({ preventScroll: true });
                            
                            // If the target can't be focused
                            if (document.activeElement !== target) {
                                target.setAttribute('tabindex', '-1');
                                target.focus({ preventScroll: true });
                            }
                        }, 1000); // Match the scroll duration
                    }
                }
            });
        }
    });

    //BACK TO TOP
    window.addEventListener('scroll', function() {
        const backTop = document.querySelector('.back-top');
        
        if (window.scrollY > 100) {
            backTop.style.display = 'block';
            backTop.classList.add('fade-in-up-sm');
            backTop.classList.remove('fade-out-down-sm');
        } else {
            backTop.style.display = 'none';
            backTop.classList.remove('fade-in-up-sm');
            backTop.classList.add('fade-out-down-sm');
        }
    });

    // Back to top click handler - to test
    document.querySelector('.back-top').addEventListener('click', function(e) {
        e.preventDefault();
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    });

    // DATEPICKER
    const datePicker = document.querySelectorAll('.datepicker input[type="text"]');
    if (datePicker.length) {
        datePicker.forEach(input => {
            // Assuming you're still using Pickadate.js
            pickadate.init(input, {
                selectYears: 90,
                selectMonths: true,
                formatSubmit: 'mm/dd/yyyy'
            });
        });
    }

    const csrfToken = window.csrfTokenValue;
    const csrfTokenName = window.csrfTokenName;
    
    // Prayer button click handler
    document.addEventListener('click', function(e) {
        // Check if clicked element is a prayer button with data-id
        if (e.target.matches('.prayer-list button[data-id]')) {
            const btn = e.target;
            const id = btn.dataset.id;
            
            // Create form data
            const data = new FormData();
            data.append('id', id);
            data.append(csrfTokenName, csrfToken);
    
            // Disable button and show loading state
            btn.disabled = true;
            btn.innerHTML = '<i class="fa fa-circle-o-notch fa-pulse"></i>';
    
            // Make the fetch request
            fetch('/actions/business-logic-module/default/add-prayer', {
                method: 'POST',
                body: data,
                headers: {
                    'X-Requested-With': 'XMLHttpRequest'
                }
            })
            .then(response => response.json())
            .then(response => {
                if (response > 0) {
                    btn.textContent = 'I Prayed For This';
                    const prayerCountSpan = btn.previousElementSibling;
                    prayerCountSpan.classList.remove('hide');
                    prayerCountSpan.querySelector('.prayer-num').textContent = response;
                } else {
                    console.log(response);
                    btn.disabled = false;
                }
    
                btn.innerHTML = 'I Prayed For This';
            })
            .catch(error => {
                console.error('Error:', error);
                btn.disabled = false;
                btn.innerHTML = 'I Prayed For This';
            });
        }
        
        // Prayer form toggle handler
        if (e.target.matches('button.prayer-form-toggle')) {
            const form = e.target.nextElementSibling;
            
            // Simple slide toggle implementation
            if (form.style.display === 'none' || !form.style.display) {
                form.style.display = 'block';
                const height = form.scrollHeight + 'px';
                form.style.overflow = 'hidden';
                form.style.height = '0px';
                form.offsetHeight; // Force reflow
                form.style.transition = 'height 0.3s ease';
                form.style.height = height;
                
                setTimeout(() => {
                    form.style.height = '';
                    form.style.overflow = '';
                    form.style.transition = '';
                }, 300);
            } else {
                form.style.height = form.scrollHeight + 'px';
                form.style.overflow = 'hidden';
                form.style.transition = 'height 0.3s ease';
                form.offsetHeight; // Force reflow
                form.style.height = '0px';
                
                setTimeout(() => {
                    form.style.display = 'none';
                    form.style.height = '';
                    form.style.overflow = '';
                    form.style.transition = '';
                }, 300);
            }
        }
    });

    //GROUPS FILTERING
    // Initial selections
    // const groupCards = document.querySelectorAll('[data-card]');
    // const groupFilters = document.querySelectorAll('[data-group-filter]');
    // const groupList = document.querySelector('[data-group-list]');
    // const filteredGroupList = document.querySelector('[data-group-filtered-list]');
    let openForm = null;
    // let currentRequest = null;

    // Load Wufoo Form function
    function loadWufooForm(elm) {
        const formDivParent = elm.querySelector('.detail-card-contact-form');
        const groupLeaderEmail = formDivParent.dataset.subscriber;
        const groupLeaderName = formDivParent.dataset.leadername;
        const groupName = formDivParent.dataset.groupname;
        const decodedGroupLeaderEmail = window.atob(groupLeaderEmail);
        const decodedGroupName = window.atob(groupName);
        const decodedLeaderName = window.atob(groupLeaderName);
        
        const formDiv = document.createElement('div');
        formDiv.id = 'wufoo-z1uux0a0068m97w';
        formDivParent.appendChild(formDiv);
        
        let z1uux0a0068m97w;
        (function(d, t) {
            const s = d.createElement(t);
            const options = {
                userName: "thebayou",
                formHash: "z1uux0a0068m97w",
                autoResize: true,
                height: "869",
                async: true,
                host: "wufoo.com",
                header: "show",
                ssl: true,
                defaultValues: `Field9=${decodedGroupLeaderEmail}&Field11=${decodedGroupName}&Field13=${decodedLeaderName}`,
            };
            
            s.src = ("https:" == d.location.protocol ? "https://" : "http://") + 
                    "secure.wufoo.com/scripts/embed/form.js";
            
            s.onload = s.onreadystatechange = function() {
                const rs = this.readyState;
                if (rs) if (rs != "complete") if (rs != "loaded") return;
                try {
                    z1uux0a0068m97w = new WufooForm();
                    z1uux0a0068m97w.initialize(options);
                    z1uux0a0068m97w.display();
                } catch (e) {}
            };
            
            const scr = d.getElementsByTagName(t)[0];
            const par = scr.parentNode;
            par.insertBefore(s, scr);
        })(document, "script");
    }

    // Close other forms function
    function closeOtherForms() {
        if (openForm !== null) {
            const contactElement = openForm.querySelector('[data-card-contact]');
            const contactShowElement = openForm.querySelector('[data-card-contact-show]');
            const formElement = openForm.querySelector('.detail-card-contact-form');
            
            contactElement.classList.remove('open');
            contactShowElement.style.display = 'block';
            formElement.innerHTML = '';
            openForm = null;
        }
    }

    // // Set group card actions function
    // function setGroupCardActions(list) {
    //     const cards = list.querySelectorAll('.detail-card');
    //     cards.forEach(card => {
    //         const cardToggle = card.querySelector('[data-card]');
    //         const contactShowBtn = card.querySelector('[data-card-contact-show]');
            
    //         // Remove existing listeners if any
    //         cardToggle.replaceWith(cardToggle.cloneNode(true));
    //         if (contactShowBtn) {
    //             contactShowBtn.replaceWith(contactShowBtn.cloneNode(true));
    //         }
            
    //         // Add new listeners
    //         card.querySelector('[data-card]').addEventListener('click', () => {
    //             card.classList.toggle('selected');
    //             closeOtherForms();
    //         });
            
    //         const newContactShowBtn = card.querySelector('[data-card-contact-show]');
    //         if (newContactShowBtn) {
    //             newContactShowBtn.addEventListener('click', (evt) => {
    //                 evt.preventDefault();
    //                 card.querySelector('[data-card-contact]').classList.add('open');
    //                 newContactShowBtn.style.display = 'none';
    //                 closeOtherForms();
    //                 loadWufooForm(card);
    //                 openForm = card;
    //             });
    //         }
    //     });
    // }

    // // Add filter change handlers
    // groupFilters.forEach(filter => {
    //     const select = filter.querySelector('select');
    //     select.addEventListener('change', async () => {
    //         let selectedValues = [];
            
    //         // Abort previous request if exists
    //         if (currentRequest) {
    //             currentRequest.abort();
    //         }
            
    //         // Collect all selected values
    //         groupFilters.forEach(filterItem => {
    //             const selectValue = filterItem.querySelector('select').value;
    //             if (selectValue !== '') {
    //                 selectedValues.push(selectValue);
    //             }
    //         });
            
    //         if (selectedValues.length === 0) {
    //             filteredGroupList.innerHTML = '';
    //         } else {
    //             filteredGroupList.innerHTML = '<div class="loader"><div class="animsition-loading"></div></div>';
                
    //             try {
    //                 const controller = new AbortController();
    //                 currentRequest = controller;
                    
    //                 const response = await fetch(`/groups/${selectedValues.join(',')}`, {
    //                     signal: controller.signal
    //                 });
    //                 const data = await response.text();
                    
    //                 // Update filtered list
    //                 filteredGroupList.innerHTML = data;
                    
    //                 // Handle clear filters button if it exists
    //                 const clearFiltersBtn = filteredGroupList.querySelector('[data-clear-filters]');
    //                 if (clearFiltersBtn) {
    //                     clearFiltersBtn.addEventListener('click', (evt) => {
    //                         evt.preventDefault();
    //                         filteredGroupList.innerHTML = '';
    //                         groupFilters.forEach(filterItem => {
    //                             filterItem.querySelector('select').value = '';
    //                         });
    //                         openForm = null;
    //                     });
    //                 }
                    
    //                 // Set actions for new cards
    //                 setGroupCardActions(filteredGroupList);
                    
    //             } catch (error) {
    //                 if (error.name === 'AbortError') {
    //                     console.log('Fetch aborted');
    //                 } else {
    //                     console.error('Fetch error:', error);
    //                 }
    //             }
    //         }
    //     });
    // });

    // // Initialize group card actions
    // setGroupCardActions(groupList);
}

document.addEventListener('DOMContentLoaded', function() {

    window.Alpine = Alpine
    Alpine.start()

    // Initialize Priority Nav Scroller
    const navScroller = document.querySelector('.nav-scroller');
    if (navScroller) {
        const priorityNavScrollerDefault = PriorityNavScroller({
            selector: '.nav-scroller',
            navSelector: '.nav-scroller-nav',
            contentSelector: '.nav-scroller-content',
            itemSelector: '.nav-scroller-item',
            buttonLeftSelector: '.nav-scroller-btn--left',
            buttonRightSelector: '.nav-scroller-btn--right',
            scrollStep: 75,
            debug: true,  // Enable debugging
            initClass: 'nav-scroller-init', // Class added when initialized
            dragClass: 'nav-scroller-drag', // Class added while dragging
            draggable: true, // Enable drag functionality
            usePadding: false // Consider padding in calculations
        });
    }
  
    


    // Initialize scripts
    initializeScripts();

});

const eventCarousel = document.getElementById('event-carousel');

    if (eventCarousel) {
        new Flickity(eventCarousel, {
            cellAlign: 'left',
            selectedAttraction: 0.01,
            friction: 0.15,
            freeScroll: false,
            prevNextButtons: true,
            pageDots: true,
            groupCells: 1,
            contain: true,
            draggable: true,
            dragThreshold: 10,
            lazyLoad: 3,

        });
    }
